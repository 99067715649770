<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="workOrdersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
            :height="$vuetify.breakpoint.mdAndDown ? '67vh' : '56vh'"
            multiple-expand
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">DELIVERIES</h1>
                        <v-spacer />
                        <v-combobox
                            v-model="selectedProcess"
                            hide-details
                            label="Process"
                            prepend-icon="mdi-tools"
                            :items="availableProcesses"
                            item-text="name"
                            class="mb-2 mr-5"
                            :disabled="availableProcesses.length == 1"
                            :readonly="availableProcesses.length == 1"
                            @change="structureData()"
                            style="max-width: 250px;"
                        />
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.projectManager`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.projectManager.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template
                v-slot:expanded-item="{
                    headers,
                    item: item,
                }"
            >
                <td
                    :colspan="headers.length"
                    class="pa-4 ma-0 outlined"
                    :style="{ background: 'white' }"
                >
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-data-table
                                :headers="subheaders"
                                :items="item.items"
                                class="elevation-0"
                                :mobile-breakpoint="0"
                                :loading="loading"
                                disable-pagination
                                hide-default-footer
                            >
                                <!--ITEMS-->
                                <template v-slot:[`item.qty`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{ item.quantity }}
                                        </p>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import { mapMutations, mapState } from 'vuex'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'Deliveries',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    components: {},
    data: () => ({
        workOrder: false,
        projectToFilter: undefined,
        loading: false,
        workOrders: [],
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 120,
            },
            {
                text: 'PROJECT MANAGER',
                value: 'projectManager',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
                width: 240,
            },
            {
                text: 'QTY',
                value: 'woQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE TO CLAIM',
                value: 'claimQty',
                align: 'center',
                sortable: true,
            },
            {
                text: 'PENDING TO RECEIVE',
                value: 'pendingQty',
                align: 'center',
                sortable: true,
            },
            {
                text: 'AVAILABLE FOR DELIVERY',
                value: 'deliveryQty',
                align: 'center',
                sortable: true,
            },
        ],
        subheaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE TO CLAIM',
                value: 'claimQty',
                align: 'center',
                sortable: true,
            },
            {
                text: 'PENDING TO RECEIVE',
                value: 'pendingQty',
                align: 'center',
                sortable: true,
            },
            {
                text: 'AVAILABLE FOR DELIVERY',
                value: 'deliveryQty',
                align: 'center',
                sortable: true,
            },
        ],
        company: JSON.parse(localStorage.getItem('company')),
        listener: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        settingProcesses: undefined,
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        qualityProcess: {
            id: 'quality',
            name: 'quality',
        },
        user: {},
        structuredWorkOrders: [],
        expanded: [],
        selectedProcess: { name: '' },
        availableProcesses: [],
    }),
    computed: {
        ...mapState(['notificationResource']),
        workOrdersFiltered() {
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (conditions.length > 0) {
                return this.structuredWorkOrders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            this.sort()
            return this.structuredWorkOrders
        },
    },
    async mounted() {
        try {
            this.loading = true
            SocketioService.joinRoom(`${this.companyId}-WO`)
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            //set available processes
            if (this.user.process && !this.user.processes) {
                this.availableProcesses = [
                    this.settingProcesses.processes.find(
                        p => p.id == this.user.process.id
                    ),
                ]
            } else if (this.user.processes) {
                this.availableProcesses = this.settingProcesses.processes.filter(
                    p => this.user.processes.includes(p.id)
                )
            }
            this.selectedProcess = this.availableProcesses[0]
            const {
                data: { users },
            } = await API.getLiteUsers()

            const projects = await API.getLiteProjects({ all: true })
            let query = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('workOrders')
                .where('status', 'in', ['approved', 'finished'])

            this.listener = await query.onSnapshot(async docSnapshot => {
                docSnapshot.docChanges().forEach(async change => {
                    const workOrder = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    const userIndex = users.findIndex(
                        u => u.id == workOrder.projectManager
                    )
                    if (userIndex >= 0) {
                        workOrder.projectManager = users[userIndex]
                    } else {
                        workOrder.projectManager = {
                            id: workOrder.projectManager,
                        }
                        workOrder.projectManager.name = ''
                    }
                    if (change.type === 'added') {
                        const projectIndex = projects.findIndex(
                            p => p.id == workOrder.projectId
                        )
                        if (projectIndex >= 0) {
                            workOrder.project = projects[projectIndex]
                        } else {
                            workOrder.project = await API.getProject(
                                workOrder.projectId
                            )
                            projects.push(workOrder.project)
                        }
                        this.workOrders.splice(0, 0, workOrder)
                    }
                    if (change.type === 'modified') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            const projectIndex = projects.findIndex(
                                p => p.id == workOrder.projectId
                            )
                            if (projectIndex >= 0) {
                                workOrder.project = projects[projectIndex]
                            } else {
                                workOrder.project = await API.getProject(
                                    workOrder.projectId
                                )
                                projects.push(workOrder.project)
                            }
                            this.workOrders.splice(index, 1, workOrder)
                        }
                    }
                    if (change.type === 'removed') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            this.workOrders.splice(index, 1)
                        }
                    }
                })
                this.structureData()
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        structureData() {
            let structuredWorkOrders = _.cloneDeep(this.workOrders)
            // use user process or process selected
            if (this.selectedProcess && this.selectedProcess.name != '') {
                structuredWorkOrders.forEach(workOrder => {
                    workOrder.items.forEach(item => {
                        item.claimQty = 0
                        item.pendingQty = 0
                        item.producedQty = 0
                        item.prevProducedQty = 0
                        item.receivedQty = 0
                        item.deliveryQty = 0
                        item.prevDeliveredQty = 0
                        item.sentQty = 0

                        //previous process
                        const processIndex = item.processes.findIndex(
                            p => p == this.selectedProcess.id
                        )

                        let previousProcess
                        if (processIndex > 0) {
                            previousProcess = item.processes[processIndex - 1]
                        }

                        if (item.production) {
                            //get qty produced by the user process or released by quality
                            item.producedQty = item.production.reduce(
                                (accum, productionRegister) =>
                                    accum +
                                    (productionRegister.process.id ==
                                    this.selectedProcess.id
                                        ? productionRegister.qty
                                        : 0),
                                0
                            )
                            if (item.processes.length - 1 > processIndex) {
                                if (
                                    item.processes[processIndex + 1] ==
                                    this.qualityProcess.id
                                ) {
                                    item.producedQty = 0
                                    if (item.quality) {
                                        item.producedQty = item.quality.reduce(
                                            (accum, qualityRegister) =>
                                                accum +
                                                (qualityRegister.releaseQty ||
                                                    0),
                                            0
                                        )
                                    }
                                }
                            }

                            //get qty produced by the previous process
                            if (previousProcess) {
                                if (previousProcess == this.qualityProcess.id) {
                                    if (item.quality) {
                                        item.prevProducedQty = item.quality.reduce(
                                            (accum, qualityRegister) =>
                                                accum +
                                                (qualityRegister.releaseQty ||
                                                    0),
                                            0
                                        )
                                    }
                                } else {
                                    item.prevProducedQty = item.production.reduce(
                                        (accum, productionRegister) =>
                                            accum +
                                            (productionRegister.process.id ==
                                            previousProcess
                                                ? productionRegister.qty
                                                : 0),
                                        0
                                    )
                                }
                            }
                        }

                        if (item.deliveries) {
                            const sent = item.deliveries.filter(
                                register =>
                                    register.prevProcess.id ==
                                    this.selectedProcess.id
                            )

                            item.sentQty = sent.reduce(
                                (total, register) => total + register.qty,
                                0
                            )

                            const deliveries = item.deliveries.filter(
                                register =>
                                    register.nextProcess.id ==
                                    this.selectedProcess.id
                            )

                            //delivered by previous process
                            item.prevDeliveredQty = deliveries.reduce(
                                (total, register) => total + register.qty,
                                0
                            )

                            const received = deliveries.filter(
                                register => register.receivedBy
                            )

                            const pendingToReceive = deliveries.filter(
                                register => !register.receivedBy
                            )

                            // received from prev process
                            item.receivedQty = received.reduce(
                                (total, register) => total + register.qty,
                                0
                            )

                            // prending to receive
                            item.pendingQty = pendingToReceive.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }

                        //Qty available to claim
                        item.claimQty =
                            item.prevProducedQty - item.prevDeliveredQty

                        //Qty available for delivery
                        item.deliveryQty = item.producedQty - item.sentQty
                    })
                    workOrder.claimQty = this.getTotalClaim(workOrder)
                    workOrder.pendingQty = this.getTotalPending(workOrder)
                    workOrder.deliveryQty = this.getTotalDelivery(workOrder)
                    workOrder.woQty = this.getTotalWOQty(workOrder)

                    this.structuredWorkOrders = structuredWorkOrders
                })
            }
        },
        getTotalClaim(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) => accum + item.claimQty,
                    0
                )
            } else {
                return 0
            }
        },
        getTotalDelivery(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) => accum + item.deliveryQty,
                    0
                )
            } else {
                return 0
            }
        },
        getTotalWOQty(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) => accum + item.quantity,
                    0
                )
            } else {
                return 0
            }
        },
        getTotalPending(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) => accum + (item.pendingQty || 0),
                    0
                )
            } else {
                return 0
            }
        },
        sort() {
            this.structuredWorkOrders.sort(
                (a, b) => this.getTotalPending(b) - this.getTotalPending(a)
            )
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px;
}
.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}
</style>
